import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar } from '@mui/material';

// project imports
import Header from './Header';
import Footer from './Footer';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
    ...theme.typography.mainContent,
    backgroundColor: 'white',
    padding: 0,
    margin: 0
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* header */}
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: '#E6F0FF'
                    }}
                >
                    <Toolbar>
                        <Header />
                    </Toolbar>
                </AppBar>

                {/* main content */}
                <Main theme={theme}>
                    <Outlet />
                </Main>
            </Box>
            <Footer />
        </>
    );
};

export default MainLayout;
