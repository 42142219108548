import { Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Container maxWidth="md" sx={{ mt: 5 }}>
            <Typography variant="h4" align="center" gutterBottom>
                Privacy Policy
            </Typography>
            <Typography variant="h4" align="center" gutterBottom>
                Skip to main content.<br></br>End User License Agreement.<br></br>Last revised on August 2, 2021
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                This End User License Agreement ("EULA") is a binding legal agreement between you, as an individual or entity, and LinkedIn.
                By downloading, installing, or using this application for Android, iOS or other mobile platform, as applicable (the
                "Software"), you agree to be bound by the terms of this EULA. If you do not agree to this EULA, do not use the Software and
                delete it. You agree that installation or use of the Software signifies that you have read, understood, and agree to be
                bound by this EULA.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                For clarification, any LinkedIn service (“LinkedIn Service”) accessed through the Software, or otherwise, is also subject to
                the LinkedIn User Agreement, Privacy Policy and Cookie Policy. Additional terms may apply to specific features. The Software
                is provided to you under this EULA solely for your private, non-commercial use. Use of the Software or any LinkedIn Service
                within an organization requires a commercial agreement for the applicable LinkedIn Service.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                We use the term “Designated Countries” to refer to countries in the European Union (EU), European Economic Area (EEA), and
                Switzerland. If you reside in the “Designated Countries”, you are entering into this contract with LinkedIn Ireland
                Unlimited Company (“LinkedIn Ireland”) and LinkedIn Ireland will be the controller of your personal data provided to, or
                collected by or for, or processed in connection with the Software or LinkedIn Service. If you reside outside of the
                Designated Countries, you are entering into this contract with LinkedIn Corporation (“LinkedIn Corp”) and LinkedIn Corp will
                be the controller of your personal data provided to, or collected by or for, or processed in connection with the Software or
                LinkedIn Service.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                1. Description of Software The Software is a downloadable software application that enables you to access LinkedIn
                functionality directly from your Android, iPhone, iPad or other mobile device supported by LinkedIn (“Device”). You may
                download the Software whether or not you use the LinkedIn Service, but you must associate it with your LinkedIn account to
                enable its full functionality.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                2. License LinkedIn hereby grants you, subject to the terms and conditions of this EULA, a non-exclusive, non-transferable
                personal license to:
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                Use the Software for your own personal use; Install the Software on only one Device; and Make one copy of the Software in
                any machine readable form solely for back-up purposes, provided you reproduce the Software in its original form and with all
                proprietary notices on the back-up copy. For clarity, the foregoing is not intended to prohibit you from installing and
                backing-up the Software for another Device on which you also agreed to the EULA. Each instance of this EULA that you agree
                to grants you the aforementioned rights in connection with the installation, use and back-up of one copy of the Software on
                one Device.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                3. Title Title, ownership and all rights (including without limitation intellectual property rights) in and to the Software
                shall remain with LinkedIn. Except for those rights expressly granted in this EULA, no other rights are granted, whether
                express or implied.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                4. Restrictions You understand and agree that you shall only use the Software in a manner that complies with any and all
                applicable laws in the jurisdictions in which you use the Software. Your use shall be in accordance with applicable
                restrictions concerning privacy and intellectual property rights.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                You may not: Create derivative works based on the Software; Use the Software for any purpose other than as described herein;
                Copy or reproduce the Software except as described in this EULA; Sell, assign, license, disclose, distribute or otherwise
                transfer or make available the Software or any copies of the Software in any form to any third parties; Alter, translate,
                decompile, reverse assemble or reverse engineer the Software, or attempt to do any of the foregoing, except to the extent
                this prohibition is not permitted under an applicable law; or Remove or alter any proprietary notices or marks on the
                Software. 5. Personal Information and Privacy Our handling of personal information we collect through the LinkedIn Services
                or the Software is governed by the LinkedIn Privacy Policy.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                6. No Warranty LINKEDIN DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE SOFTWARE WILL MEET ANY REQUIREMENTS OR NEEDS
                YOU MAY HAVE, OR THAT THE SOFTWARE WILL OPERATE ERROR FREE, OR IN AN UNINTERRUPTED MANNER, OR THAT ANY DEFECTS OR ERRORS
                WILL BE CORRECTED, OR THAT THE SOFTWARE IS FULLY COMPATIBLE WITH ANY PARTICULAR PLATFORM. THE SOFTWARE IS OFFERED ON AN
                "AS-IS" BASIS AND NO WARRANTY, EITHER EXPRESS OR IMPLIED, IS GIVEN. LINKEDIN EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE AND NON-INFRINGEMENT. SOME JURISDICTIONS DO NOT ALLOW THE WAIVER OR EXCLUSION OF IMPLIED WARRANTIES SO
                THEY MAY NOT APPLY TO YOU.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                7. Right to Terminate or Modify Software LinkedIn may modify the Software and this EULA without notice. You may cease use of
                the Software at any time. Either party may terminate this EULA at any time, with or without notice.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                8. U.S. Government Restricted Rights By accepting delivery, the government agrees that the Software and accompanying
                documentation qualifies as “commercial” computer software within the meaning of the applicable acquisition regulations. The
                terms and conditions of this EULA govern the government's use and disclosure of the Software and supersede any conflicting
                terms and conditions. If this EULA fails to meet the government's needs or is inconsistent in any way with federal law, the
                government must return the Software, unused, to LinkedIn.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                9. Open Source The Software may contain or be provided together with open source software. Each item of open source software
                is subject to its own applicable license terms, which can be found at
                https://www.linkedin.com/legal/l/open-source-mobile-apps and/or in the Software documentation or the applicable help,
                notices, about or source files. Copyrights to the open source software are held by the respective copyright holders
                indicated therein.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                10. Indemnification To the fullest extent permitted by law, you agree to indemnify and otherwise hold harmless LinkedIn
                Corporation, its officers, employees, agents, subsidiaries, affiliates and other partners from any direct, indirect,
                incidental, special, consequential or exemplary damages arising out of, relating to, or resulting from your use of the
                Software or any other matter relating to the Software.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                11. Limitation of Liability YOU EXPRESSLY UNDERSTAND AND AGREE THAT LINKEDIN SHALL NOT BE LIABLE FOR ANY INDIRECT,
                INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
                GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF LINKEDIN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES). IN NO
                EVENT WILL LINKEDIN'S AGGREGATE LIABILITY TO YOU EXCEED THE AMOUNT OF LICENSING FEES PAID BY YOU TO LINKEDIN. THESE
                LIMITATIONS AND EXCLUSIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. SOME
                JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OF DAMAGES AND/OR EXCLUSIONS OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
                DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                12. Export Restrictions This EULA is expressly made subject to any laws, regulations, orders or other restrictions on the
                export of software from the United States of America, and may be subject to export and import regulations of other
                countries. You acknowledge and agree not to import, export, re-export, transfer or use, directly or indirectly, the Software
                without compliance with such laws, regulations, orders or other restrictions.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
                13. General If you live in the Designated Countries:<br></br> a) you and LinkedIn Ireland agree that the laws of Ireland,
                excluding conflict of laws rules, shall exclusively govern any dispute relating to this EULA, the Software and/or LinkedIn
                Service; and<br></br> b) you and LinkedIn Ireland agree that claims and disputes can be litigated only in Dublin, Ireland,
                and we each agree to personal jurisdiction of the courts located in Dublin, Ireland. For others outside of Designated
                Countries, including those who live outside of the United States:<br></br> a) you and LinkedIn agree that the laws of the
                State of California, U.S.A., excluding its conflict of laws rules, shall exclusively govern any dispute relating to this
                EULA, the Software and/or LinkedIn Service; and<br></br> b) you and LinkedIn both agree that all claims and disputes can be
                litigated only in the federal or state courts in Santa Clara County, California, USA, and you and LinkedIn each agree to
                personal jurisdiction in those courts. This EULA constitutes the entire agreement between you and LinkedIn regarding the
                Software. If any provision of this EULA is held by a court of competent jurisdiction to be contrary to law, such provision
                will be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent
                allowed by law and the remaining provisions of this EULA will remain in full force and effect. You may not assign this EULA,
                and any assignment of this EULA by you will be null and void. LinkedIn, LinkedIn (stylized), the "in" logos, and other
                LinkedIn-owned logos and names are trademarks of LinkedIn and its affiliates. You agree not to display or use these
                trademarks in any manner without LinkedIn's prior, written permission. The section titles and numbering of this EULA are
                displayed for convenience and have no legal effect.
            </Typography>
        </Container>
    );
};

export default PrivacyPolicy;
