// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    alert: { open: false, severity: 'info', message: '' },
    loading: false,
    opened: true,
    currentCompany: null,
    companyLeftSide: {},
    companyAboutData: {},
    companySocialData: {},
    companyMainStructData: {},
    companyBranchesData: {},
    companyStaffData: {},
    companyOutSourceData: {},
    companyClientsData: {},
    editCompanyProfilePicture: { open: false, file: null, imageURL: '', id: '' },
    editModal: { open: false, key: '', value: '', _id: '', fromWhere: '' },
    appendArrayModal: { open: false, key: '', value: undefined, _id: '', fromWhere: '' },
    openEditLogosArray: { open: false },
    addLogoinArray: { open: false, file: null, imageURL: '', id: '' },
    talentProfileData: {},
    allOptions: [],
    chooseLoginTypeModal: { open: true, value: 'talent' },
    chooseRegisterTypeModal: { open: true, value: '' },
    currentTalent: null,
    editModalTalent: { open: false, key: '', value: '', _id: '', type: '' },
    appendArrayModalTalent: { open: false, key: '', value: undefined, _id: '' },
    editCompanyProfilePictureTalent: { open: false, file: null, imageURL: '', id: '' },
    videosEdit: { open: false, videos: [], _id: '', file: null, videoURL: '' },
    talentVideos: [],
    docsEdit: { open: false, documents: [], _id: '', file: null, docURL: '' },
    talentDocs: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case actionTypes.UPDATE_CURRENT_COMPANY:
            return { ...state, currentCompany: action.payload };

        case actionTypes.UPDATE_ALERT:
            return { ...state, alert: action.payload };

        case actionTypes.START_LOADING:
            return { ...state, loading: true };

        case actionTypes.END_LOADING:
            return { ...state, loading: false };

        case actionTypes.COMPANY_LEFT_SIDE:
            return { ...state, companyLeftSide: action.payload };

        case actionTypes.COMPANY_ABOUT_DATA:
            return { ...state, companyAboutData: action.payload };

        case actionTypes.COMPANY_SOCIAL_DATA:
            return { ...state, companySocialData: action.payload };

        case actionTypes.COMPANY_MAINSTRUCT_DATA:
            return { ...state, companyMainStructData: action.payload };

        case actionTypes.COMPANY_BRANCHES_DATA:
            return { ...state, companyBranchesData: action.payload };

        case actionTypes.COMPANY_STAFF_DATA:
            return { ...state, companyStaffData: action.payload };

        case actionTypes.COMPANY_OUTSOURCE_DATA:
            return { ...state, companyOutSourceData: action.payload };

        case actionTypes.COMPANY_CLIENTS_DATA:
            return { ...state, companyClientsData: action.payload };

        case actionTypes.OPEN_UPDATE_PROFILE_IMAGE:
            return { ...state, editCompanyProfilePicture: action.payload };

        case actionTypes.OPEN_EDIT_MODAL:
            return { ...state, editModal: action.payload };

        case actionTypes.OPEN_APPEND_ARRAY_MODAL:
            return { ...state, appendArrayModal: action.payload };

        case actionTypes.OPEN_EDIT_LOGOS_ARRAY:
            return { ...state, openEditLogosArray: action.payload };

        case actionTypes.OPEN_ADD_LOGOS_ARRAY:
            return { ...state, addLogoinArray: action.payload };

        case actionTypes.GET_TALENT_PROFILE_DATA:
            return { ...state, talentProfileData: action.payload };

        case actionTypes.CHOOSE_LOGIN_TYPE_MODAL:
            return { ...state, chooseLoginTypeModal: action.payload };

        case actionTypes.CHOOSE_REGISTER_TYPE_MODAL:
            return { ...state, chooseRegisterTypeModal: action.payload };

        case actionTypes.UPDATE_CURRENT_TALENT:
            return { ...state, currentTalent: action.payload };

        case actionTypes.OPEN_EDIT_MODAL_TALENT:
            return { ...state, editModalTalent: action.payload };

        case actionTypes.OPEN_APPEND_ARRAY_MODAL_TALENT:
            return { ...state, appendArrayModalTalent: action.payload };

        case actionTypes.OPEN_UPDATE_PROFILE_IMAGE_TALENT:
            return { ...state, editCompanyProfilePictureTalent: action.payload };

        case actionTypes.GET_FIELD_OPTION:
            return { ...state, allOptions: action.payload };

        case actionTypes.OPEN_PROFILE_EDIT_VIDEOS_TALENT:
            return { ...state, videosEdit: action.payload };

        case actionTypes.TALENT_VIDEOS:
            return { ...state, talentVideos: action.payload };

        case actionTypes.OPEN_PROFILE_EDIT_DOCS_TALENT:
            return { ...state, docsEdit: action.payload };

        case actionTypes.TALENT_DOCS:
            return { ...state, talentDocs: action.payload };

        default:
            return state;
    }
};

export default customizationReducer;
