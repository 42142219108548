import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthGuard from './AuthGuard';
import InsideMainLayout from 'layout/MainLayout/InsideMainLayout';

// dashboard routing
const Home = Loadable(lazy(() => import('views/home/Home')));
const CompanyProfile = Loadable(lazy(() => import('views/company/profile/CompanyProfile')));
const EditCompanyProfile = Loadable(lazy(() => import('views/company/editProfile/EditCompanyProfile')));
const TalentProfile = Loadable(lazy(() => import('views/talent/profile/TalentProfile')));
const EditTalentProfile = Loadable(lazy(() => import('views/talent/editProfile/EditTalentProfile')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: <Home />
        },
        {
            path: 'company/:id',
            element: <CompanyProfile />
        },
        {
            path: 'talent/:id',
            element: <TalentProfile />
        },
        {
            path: '',
            element: (
                <AuthGuard>
                    <InsideMainLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: 'company/edit',
                    element: <EditCompanyProfile />
                },
                {
                    path: 'talent/edit',
                    element: <EditTalentProfile />
                }
            ]
        }
    ]
};

export default MainRoutes;
