import { Navigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// eslint-disable-next-line react/prop-types
const AutoLogin = ({ children }) => {
    const x_access_token = localStorage.getItem('x_access_token');
    const token_talent = localStorage.getItem('x_access_token_talent');
    const token = x_access_token;

    if (!token && !token_talent) {
        return children;
    }

    if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 > new Date().getTime()) {
            return <Navigate to="/" replace={true} />;
        }
    }

    if (token_talent) {
        const decodedTokenTalent = jwtDecode(token_talent);
        if (decodedTokenTalent.exp * 1000 > new Date().getTime()) {
            return <Navigate to="/" replace={true} />;
        }
    }

    return children;
};

export default AutoLogin;
