// project imports

import PrivacyPolicy from 'views/pages/staticPages/PrivacyPolicy';

const Pages = {
    path: '/',
    children: [
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy />
        }
    ]
};

export default Pages;
