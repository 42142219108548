import { Button } from '@mui/material';
import { Login } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1)
    }
}));

export default function LoginButton() {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleOnClick = async (e) => {
        e.preventDefault();
        navigate('/login');
    };

    return (
        <div>
            <Button onClick={handleOnClick} variant="outlined" color="primary" className={classes.button} endIcon={<Login />}>
                Login
            </Button>
        </div>
    );
}
