import MinimalLayout from 'layout/MinimalLayout';
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AutoLogin from './AutoLogin';

// dashboard routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const Register = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTH ROUTING ||============================== //

const AuthRoutes = {
    path: '',
    element: (
        <AutoLogin>
            <MinimalLayout />
        </AutoLogin>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/register',
            element: <Register />
        }
    ]
};

export default AuthRoutes;
