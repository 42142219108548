// Packages
import { useSelector } from 'react-redux';

// MUI
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// Tailwind CSS
import './index.css';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import Notification from 'views/pages/authentication/auth-forms/Notification';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <Notification />
                <CssBaseline />
                <Routes />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
