import { AppBar, Toolbar, Typography } from '@mui/material';

const Footer = () => (
    <AppBar position="static" elevation={0} component="footer" color="default">
        <Toolbar style={{ justifyContent: 'center' }}>
            <Typography variant="caption">Copyright ©2023 Emplopedia. All Rights Reserved</Typography>
        </Toolbar>
    </AppBar>
);

export default Footer;
